import React from "react";
import { Container, Row } from "react-bootstrap";
import "./Transformations.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Transformations = () => {
  const cards = [
    {
      id: 1,
      image: "images/Group 464.png",
      name: "Shivani Lagad",
      destination: "",
      header: "Gained 10 kg in 90 days",
      description:
        "My chronic fatigue has been resolved. My immunity has improved greatly due to weight gain, and I no longer suffer from frequent colds and flu.",
    },
    {
      id: 2,
      image: "images/Group 465.png",
      name: "Mrs Santosh Sharma",
      destination: "Profession: Retail sector job",
      header: "Lost 12.5 kg in 90 days",
      description:
        "I used to suffer from severe migraines every two days, relying heavily on painkillers. Within 10 days of joining, I no longer needed painkillers, and I’m thriving now.",
    },
    {
      id: 3,
      image: "images/Group 466.png",
      name: "Salil Patil",
      destination: "Profession: Retail sector job",
      header: "Lost 16.7 kg in 150 days",
      description:
        "Despite no prior workout experience and breathlessness issues, along with bad eating habits, which were taking a toll on my health, I overcame my laziness and discomforts. Now, I can do a full 45-minute HIIT session with great energy!",
    },
    {
      id: 4,
      image: "images/Group 467.png",
      name: "Mrs Sushmita Thombre",
      destination: "",
      header: "Lost 26 kg in 6 months",
      description:
        "Being diagnosed with Hypothyroidism I was finding it difficult to go through the day as constant low energy and irritation made me crankier, including low sleep issues, acidity, and constipation, which is totally resolved. My thyroid medication dosage is also reduced by 50 mg.",
    },
    {
      id: 5,
      image: "images/Group 468.png",
      name: "Kaisar Baghwan",
      destination: "Profession: Businessman ",
      header: "Lost 11 kg in 3 months",
      description:
        "I was a heavy smoker, smoking more than 14 cigarettes a day. Didn’t have any clue about healthy eating habits. Never worked out before. Now I have come down to 2-3 cigarettes a day and committed to quitting smoking and thriving for a healthier and stronger body.",
    },
    {
      id: 6,
      image: "images/Pompi.png",
      name: "Pompi Bailung",
      destination: "Profession: teacher",
      header: "lost 15 kg in 5 months",
      description:
        "My energy levels improved, my skin started glowing & I gained more confidence and started clicking more pictures of myself which is a big thing because I used to be very conscious before when clicking my pictures. Thank you team H3 and my coach for making me confident again.",
    },
    {
      id: 7,
      image: "images/Shalini.png",
      name: "Shalini Dungdung",
      destination: "Profession: student",
      header: "lost 10 kg in 3 months",
      description:
        "I love dancing but I was unable to lose weight through dancing, due to lack of guidance or knowledge of diet and planned workout. Now I love myself than before because I look and feel so much better than before.",
    },
    {
      id: 8,
      image: "images/Beena.png",
      name: "Beena Kerketta",
      destination: "Profession: homemaker",
      header: "lost 14 kg in 7 months.",
      description:
        "“The H3 weight loss program helped me lose weight and build mental and physical strength. I used to suffer from frequent aches and low energy. I tried the gym and various diets but saw no results. The H3 team explains everything related to our health in such simple manner and eventually helped me lose weight and get healthier and stronger.“",
    },
    {
      id: 9,
      image: "images/Usha.png",
      name: "Usha Khalane",
      destination: "profession: homemaker",
      header: "lost 17 kg in 5 months.",
      description:
        "“The H3 community has been one of the biggest blessings in my life. I came here with a last hope of losing weight. After being diagnosed with hormonal imbalances, I kept gaining weight, which severely affected my health and family life. I tried different methods to lose weight but saw no results. Finally, my niece introduced me to the H3 community, and it changed my life completely.”",
    },
    {
      id: 10,
      image: "images/Sayali.png",
      name: "Sayali Ravindra Kale",
      destination: "profession: homemaker",
      header: "lost 30 kg in 6 months.",
      description:
        "I gained a lot of weight during my pregnancy. So post partum I decided to go for weight loss. I loved how simple their weight loss plan was which helped me to get the results I wanted without compromising on my health or energy levels.",
    },
    {
      id: 11,
      image: "images/Pooja.png",
      name: "Pooja Rungta",
      destination: "profession: homemaker",
      header: "lost 19 kg in 8 months.",
      description:
        "I visited the H3 Fitclub with my friend just to accompany her, I didn’t plan on joining any weight loss program. But I did my body fat analysis and got a consultation done along with my friend. That’s when I realised may be I can try this. I joined the H3’s weight loss program to try and today I am 19 kgs down and still ongoing. I feel much more energetic and healthier today.",
    },
    {
      id: 12,
      image: "images/Roshni.png",
      name: "Roshni Xess",
      destination: "profession: homemaker",
      header: "lost 30 kg in 6 months.",
      description:
        " I gained a lot of weight during my 2nd pregnancy so I decided to get on The H3’s post partum weight loss plan. I used to have acidity and digestion issues but today I am all good. Love how well they guide and most importantly their constant check ins and weekly tracking is just something very commendable.",
    },
  ];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <>
      <Container fluid className="cardsdiv">
        <Container>
          <Row>
            <div className="main-heading">
              <h1>
                Real Transformations,
                <span style={{ color: "#00AFF0" }}> Real Results.</span>{" "}
              </h1>
              <p>Be the next success story with The H3 Fitclub</p>
            </div>
            <div className="slider-container">
              <Slider {...settings}>
                {cards.map((card) => (
                  <div key={card.id} className="cardservice">
                    <img src={card.image} alt={card.name} />
                    <h3 className="mt-3">{card.name}</h3>
                    <p>{card.destination}</p>
                    <h5>{card.header}</h5>
                    <p className="cardsdescription">{card.description}</p>
                  </div>
                ))}
              </Slider>
            </div>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Transformations;
