import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./Meet.css";

const Meet = () => {
  const teamMembers = [
    {
      id: 1,
      image: "images/member1.png",
      name: "Sukanya",
      shortDesc:
        "(a certified nutrition & fitness trainer with over 9.5 years of professional expertise.)",
      longDesc:
        "With extensive experience which has led to life-changing transformations for thousands, including weight loss 50 kg of a single client. She is result oriented & is dedicated to provide simple and effective solutions for health and fitness tailored to individual needs.",
    },
    {
      id: 2,
      image: "images/member2.png",
      name: "Zaheer",
      shortDesc:
        "(a certified nutrition & fitness trainer with over 6 years of experience)",
      longDesc:
        "specializing in fat loss, body transformation, weight gain and abs program. Dedicated to helping professionals achieve their fitness goals through personalized training and nutrition plans.",
    },
    {
      id: 3,
      image: "images/member3.png",
      name: "Sahil",
      shortDesc:
        "(a seasoned fitness & nutrition trainer with over 4.5 years of experience)",
      longDesc:
        "specializes in HIIT, strength & dance/fun fitness, offers personalized attention to ensure result-oriented training, excels in communication and follow-ups, helping clients achieve their fitness goals efficiently.",
    },
    {
      id: 4,
      image: "images/member4.png",
      name: "Ibram",
      shortDesc:
        "(a dedicated fitness and nutrition trainer with over 4 years of experience)",
      longDesc:
        "specializes in HIIT, strength, stretching and mobility fitness. Offering personalized attention, Ibrahim is dedicated to delivering result-oriented training to help you achieve your fitness goals efficiently and effectively.",
    },
  ];

  return (
    <>
      <Container fluid className="meet-container">
        <div className="why-heading text-center mb-5">
          <h1>
            Meet the <span style={{ color: "#00ABE8" }}>Experts</span>
          </h1>
          <p>Behind Our Success</p>
        </div>
        <Container>
          <Row className="justify-content-center">
            {teamMembers.map((member) => (
              <Col key={member.id} lg={3} md={6} className="mb-4 d-flex">
                <Card className="meet-card">
                  <div className="meet-card-img-container">
                    <Card.Img src={member.image} className="meet-card-img" />
                  </div>
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div className="meet-description">
                      <Card.Title>{member.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-white">
                        {member.shortDesc}
                      </Card.Subtitle>
                    </div>
                    <Card.Text className="mt-3">{member.longDesc}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Meet;
