import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./About.css";

const About = () => {
  return (
    <Container fluid className="aboutusmain">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            <img src="images/aboutimg.png" alt="About" className="img-fluid" />
          </Col>
          <Col md={6} className="mt-4 mt-md-0 text-white aboutusseconddiv">
            <h1>About</h1>
            <h2>The H3 Fitness Club</h2>
            <p>
              At The H3 Fit Club, we're passionate about helping you achieve
              your fitness goals. We go beyond a one-size-fits-all approach. Our
              certified trainers design personalized workout plans tailored to
              your unique needs and fitness level, whether you're a seasoned
              athlete or just starting your journey.
            </p>
            <p>
              We believe in results. That's why we offer proven programs
              scientifically designed to deliver real change. Don't see the
              progress you expected? We back our commitment with a 100%
              Money-Back Guarantee. Your success is our priority. Beyond
              exceptional training, we offer continuous support. Our dedicated
              team is here to motivate and guide you every step of the way. We
              understand the importance of a holistic approach.
            </p>
            <p>
              That's why we provide personalized nutritional guidance, including
              customized diet plans and energizing pre-workout drinks to fuel
              your body optimally. Looking for a workout that burns calories and
              builds muscle fast? Our high-intensity interval training (HIIT)
              classes are perfect for you. These dynamic workouts combine
              periods of intense exercise with short recovery phases, maximizing
              your results in a shorter time.
            </p>
            <p>
              Your privacy is paramount. We maintain the strictest
              confidentiality regarding your information. Finally, we believe a
              healthy lifestyle extends beyond the gym. We offer workshops on
              preparing delicious and nutritious post-workout meals, helping you
              maintain your gains and achieve long-term wellness. Join The H3
              Fit Club and experience the difference. Let's transform your
              fitness journey together.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
