import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={3} className="footer-col">
            <img src="images/H3logo.png" alt="logo" className="footer-logo" />
          </Col>
          <Col md={4} className="footer-col">
            <h5 className="footer-heading">
              Follow Us On{" "}
              <span style={{ color: "#00ABE8" }}>Social Media</span>{" "}
            </h5>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=100086654853592"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} className="icon" />
              </a>
              <a
                href="https://www.instagram.com/theh3fitclub/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/the-h3-fitclub/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
              </a>
            </div>
          </Col>
          <Col md={5} className="footer-col">
            <h5 className="footer-heading">
              <span style={{ color: "#00ABE8" }}>Contact</span> Us
            </h5>
            <div className="contact-info">
              <p>
                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                theh3fitnessclub@gmail.com
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                +91 86240 31382
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="contact-icon"
                />
                Office no. 301, 2nd floor, Nyati Pinnacle, Lane Number 8, behind
                Isabella Society, behind Signature restaurant, Sahaney Sujan
                Park, Lullanagar, Pune, Maharashtra 411040
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
