import React from "react";
import { Container, Button } from "react-bootstrap";
import "./ThankYou.css";

const ThankYou = () => {
  return (
    <div className="thankyou-container">
      <Container>
        <div className="thankyou-content">
          <h2>Thank You!</h2>
          <p>We appreciate your interest in H3 FITNESS CLUB.</p>
          <p>
            Our team has received your information and an expert will be in
            touch shortly to further discuss the requirements in detail.
          </p>
          <Button variant="primary" href="/" className="back-btn">
            Back to Home
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ThankYou;
