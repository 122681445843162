import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./WhyChooseUs.css";

const WhyChooseUs = () => {
  const cards = [
    {
      id: 1,
      image: "images/1.png",
      title: "Personalized Attention:",
      text: "Our certified trainers create a workout plan tailored to your goals and fitness level.",
    },
    {
      id: 2,
      image: "images/3.png",
      title: "Continuous Follow-Through:",
      text: "We don't leave you hanging. Our dedicated staff will support you every step of the way.",
    },
    {
      id: 3,
      image: "images/2.png",
      title: "Result-Oriented Programs:",
      text: "Get fit faster with our proven programs designed to deliver results. Not satisfied? We offer a 100% Money-Back Guarantee.",
    },
    {
      id: 4,
      image: "images/4.png",
      title: "Nutritional Guidance:",
      text: "Fuel your body for success with customized diet plans and energizing pre-workout Drinks.",
    },
    {
      id: 5,
      image: "images/05.png",
      title: "High-Intensity Interval Training (HIIT):",
      text: "Burn calories and build muscle with our effective HIIT classes.",
    },
    {
      id: 6,
      image: "images/7.png",
      title: "Privacy Guaranteed:",
      text: "We take your privacy seriously. Your information stays confidential with us.",
    },
    {
      id: 7,
      image: "images/6.png",
      title: "Healthy Post-Workout Meals:",
      text: "Learn how to prepare delicious and nutritious meals to optimize your results.",
    },
  ];

  return (
    <>
      <Container fluid className="why-choose-us-main">
        <Container className="why-choose-us-content">
          <Row>
            <div className="why-heading text-center mb-5">
              <h1>
                Here's What{" "}
                <span style={{ color: "#00ABE8" }}>Sets Us Apart</span>
              </h1>
              <p>Be the next success story with The H3 Fitclub</p>
            </div>
            {cards.map((card, index) => (
              <Col
                key={card.id}
                lg={6}
                className={`mb-4 ${
                  index === cards.length - 1 ? "center-last-card" : ""
                }`}
              >
                <Card className="custom-card-why">
                  <Row noGutters>
                    <Col md={4}>
                      <Card.Img
                        variant="top"
                        src={card.image}
                        className="card-img-why"
                      />
                    </Col>
                    <Col md={8}>
                      <Card.Body>
                        <Card.Title>{card.title}</Card.Title>
                        <Card.Text>{card.text}</Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default WhyChooseUs;
