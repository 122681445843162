import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Cards.css";

const Cards = () => {
  return (
    <>
      <Container fluid className="cardsmain">
        <Container fluid className="py-5 senoddiv">
          <Row>
            <Col md={4}>
              <div className="custoemcard">
                <h1>
                  Monsoon-Proof{" "}
                  <span style={{ color: "#00ABE8" }}> Workouts</span>
                </h1>
                <hr className="line" />
                <p>
                  Stay active indoors or at the gym with our specially designed
                  routines.
                </p>
              </div>
            </Col>

            <Col md={4}>
              <div className="custoemcard">
                <h1>
                  Free First Demo
                  <span style={{ color: "#00ABE8" }}> Session</span>
                </h1>
                <hr className="line" />
                <p>Experience the power of personalised training.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="custoemcard">
                <h1>
                  Boost Your{" "}
                  <span style={{ color: "#00ABE8" }}>Energy & Immunity</span>
                </h1>
                <hr className="line" />
                <p>
                  Train smarter, not harder. Our expert coaches will create a
                  plan that fits your goals.
                  <br />  Our experts takes follow ups on your diet 3 times a
                  day.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Cards;
