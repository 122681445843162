import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Testimonial.css";

const Testimonial = () => {
  return (
    <>
      <Container fluid className="testimonil-contianer">
        <Container>
          <Row>
            <Col style={{ color: "white" }}>
              <div className="why-heading text-center mb-5">
                <h1>
                  Real Stories,{" "}
                  <span style={{ color: "#00ABE8" }}>Real Satisfaction</span>
                </h1>
                <p>Be the next success story with The H3 Fitclub</p>
              </div>
              <Row className="imagescoantienr">
                <Col>
                  <a
                    href="https://youtube.com/shorts/E3SFfWNinxA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="images/test2.png" alt="test1" className="testimg"/>
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://www.youtube.com/shorts/Qxk0Y4n5apg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="images/test3.png" alt="test1" className="testimg"/>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Testimonial;
