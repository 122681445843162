import React, { useState } from "react";
import "./Home.css"; // Import the CSS file
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Cards from "../Cards/Cards";
import Transformations from "../transformations/Transformations";
import Footer from "../Footer/Footer";
import emailjs from "../emailjs.config";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import About from "../About/About";
import Whychooseus from "../Whychooseus/Whychooseus";
import Testimonial from "../Testimonials/Testimonial";
import Meet from "../Meet/Meet";

const Home = () => {
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);

  const EMAILJS_SERVICE_ID = "service_ewpt39o";
  const EMAILJS_TEMPLATE_ID = "template_6rfxlkr";
  const EMAILJS_USER_ID = "GMDriQ5MVz2HnxMLZ";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    role: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Reset corresponding error flag when user starts typing
    setFormErrors({
      ...formErrors,
      [name]: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    let valid = true;
    const newFormErrors = { ...formErrors };

    if (!formData.name) {
      newFormErrors.name = true;
      valid = false;
    }

    if (!formData.email) {
      newFormErrors.email = true;
      valid = false;
    }

    if (!formData.phone) {
      newFormErrors.phone = true;
      valid = false;
    }

    if (!formData.role) {
      newFormErrors.role = true;
      valid = false;
    }

    setFormErrors(newFormErrors);

    if (valid) {
      setLoder(true);
      const templateParams = {
        to_email1: "hello@connectingdotsbs.com",
        to_email2: "mandeep@connectingdotsbs.com",
        from_name: formData.name,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        role: formData.role,
      };

      emailjs
        .send(
          EMAILJS_SERVICE_ID,
          EMAILJS_TEMPLATE_ID,
          templateParams,
          EMAILJS_USER_ID
        )
        .then(
          (response) => {
            setLoder(false);
            navigate("/thankyou");
            console.log("Email successfully sent!", response);
            setFormData({
              name: "",
              email: "",
              phone: "",
              role: "",
            });
          },
          (error) => {
            console.error("Error sending email:", error);
            setLoder(false);
          }
        );
    }
  };

  return (
    <>
      {loder ? <Loader /> : ""}
      <div className="home-container">
        <Container fluid>
          <Row className="justify-content-center align-items-center row-equal-height">
            <Col md={4} className="d-flex justify-content-center">
              <div className="component-div">
                <img src="images/Group.png" alt="logo" className="logo-image" />
              </div>
            </Col>
            <Col md={4} className="d-flex justify-content-center">
              <div className="component-div">
                <img src="images/00.png" alt="logo" className="girl-image" />
              </div>
            </Col>
            <Col md={4} className="d-flex justify-content-center">
              <div className="component-div form-container">
                <div className="heding">
                  <h1>Register Now</h1>
                  <p>Start Your Fitness Journey Today</p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicFullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter full name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      isInvalid={formErrors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      isInvalid={formErrors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid phone number.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      isInvalid={formErrors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email address.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicSelect">
                    <Form.Label>Looking For</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      required
                      isInvalid={formErrors.role}
                    >
                      <option value="">Choose...</option>
                      <option value="Weight Loss">Weight Loss</option>
                      <option value="Weight Gain">Weight Gain</option>
                      <option value="Muscle Gain">Muscle Gain</option>
                      <option value="Belly Fat loss">Belly Fat loss</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a goal.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="cutomebutton"
                  >
                    Enquire Now
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Cards />
      <About />
      <Whychooseus />
      <Transformations />
      <Testimonial />
      <Meet />
      <Footer />
    </>
  );
};

export default Home;
